<template>
    <UiHeaderBase id="uiHeader" :blok="blok" @logout="logout" />
</template>

<script lang="ts" setup>
import { useAuthStore } from '../../_base/store/auth';

defineProps<{ blok: any }>();
const authStore = useAuthStore();
const route = useRoute();

async function logout() {
    await $publicApi('logout', {
        method: 'POST',
        query: { _token: authStore.csrf },
        onResponse({ response }) {
            if (response.ok) {
                navigateTo(route.path, { external: true });
            }
        },
        onResponseError({ response }) {
            if (response) {
                console.error(response);
            }
        },
    });
}
</script>

<style lang="postcss">
#uiHeader {
    @apply bg-primary text-white py-[9px];

    .container {
        @apply max-sm:relative;
    }

    .nav {
        .nav-row {
            @apply items-stretch;
        }
        .nav-row-item {
            @apply text-[0.9rem] h-auto;

            span,
            a {
                @apply text-white;
            }

            .simple-richtext {
                * {
                    @apply text-white;
                }
            }

            .dropdown-item-content {
                [data-icon] {
                    @apply ml-2;
                }
            }

            &:not(:last-child) {
                > .dropdown-item-content,
                > .text-nav-item,
                > .link-text-nav-item,
                > .link-nav-item {
                    @apply flex items-center border-r px-4 h-auto;
                }
            }

            .text-nav-item {
                @apply px-4;
            }
        }

        .sub-dropdown {
            @apply py-2;
            .nav-dropdown {
                @apply top-0 left-[300px];
            }

            .i-ri\:arrow-down-s-line {
                @apply float-right mt-1 mr-1;
                &:before {
                    @apply content-["\f054"];
                }
            }
        }

        .nav-dropdown {
            @apply top-[30px] bg-white shadow-[0_8px_10px_rgba(0,0,0,0.125)] text-[#656565] px-2 py-2;
            @apply border border-primary md:w-[300px];

            .nav-dropdown-item {
                @apply border-b border-b-tertiary p-px;
                &:last-child {
                    @apply border-b-0 font-bold;
                    a {
                        @apply text-primary;
                    }
                }
                a,
                span {
                    @apply text-[#656565];
                }
            }

            .current-page-nav-item,
            .nav-dropdown-item:hover,
            .nav-mobile-dropdown-item:hover {
                @apply bg-primary text-white border-0;

                > a,
                > .dropdown-item-content > span {
                    @apply bg-[transparent] text-white;
                }
            }
        }
    }

    .navigation-button {
        @apply text-white top-2 right-4 bottom-auto max-sm:top-0 max-sm:right-0;
        &.is-open {
            @apply text-primary z-[50];
        }
    }
    .mobile-menu {
        @apply w-full pl-0 pt-0;

        * {
            @apply text-primary;
        }

        .close-button {
            @apply hidden;
        }

        .scroll-wrapper {
            @apply h-full;
            .scroll {
                @apply grid-cols-[repeat(3,100vw)];

                & > ul {
                    @apply pr-4 pl-10 pt-12;
                    li {
                        @apply text-base uppercase;
                    }
                    .drop-down-button {
                        [data-icon] {
                            @apply text-black;
                        }
                    }
                    .text-nav-item {
                        @apply justify-start;
                    }
                }
                &.level-2 {
                    @apply translate-x-[-100vw];
                }

                &.level-3 {
                    @apply translate-x-[-200vw];
                }

                .simple-richtext {
                    @apply w-full block;
                }

                .title {
                    @apply min-h-[auto] font-bold text-base uppercase;
                }
            }

            .level-wrapper {
                @apply relative pl-[80px] pt-0;
                &:before {
                    @apply content-[''] w-[66px] h-full block absolute bg-primary left-0 top-0;
                }

                .title {
                    @apply py-2;
                }

                hr.top {
                    @apply mt-12 border-t-0;
                }

                hr.bottom {
                    @apply hidden;
                }

                .back-button {
                    @apply text-transparent absolute z-[99] left-6 top-4;
                }

                .nav-mobile-dropdown {
                    li {
                        @apply py-2 mb-0;
                        a {
                            @apply text-base text-black uppercase pt-0;
                        }
                    }
                }
            }
        }
    }

    .text-nav-item {
        @apply w-full;
    }

    .ui-simple-rich-text {
        @apply normal-case;
        h6 {
            @apply text-base font-bold my-0;
        }
        p {
            @apply text-[9.6px] font-normal my-0;
        }
    }
}
</style>
